.numbers {
  .number {
    background-color: $brand-primary;

    &:nth-child(2) {
      background-color: lighten($brand-primary, 10%);
    }
    &:nth-child(3) {
      background-color: lighten($brand-primary, 20%);
    }
    &:nth-child(4) {
      background-color: lighten($brand-primary, 30%);
    }
  }
}

.padding-one-col {
  padding-right: calc(100% / 12);
}

.fp-item {
  background: #fff;
  margin-bottom: 40px;

  h2, h3, p {
    padding:0 20px;
  }

  h2, h3 {
    font-size: 20px;
  }
  p {

  }
}
.col_one_third {
  width: 30%;
  margin-left: 1.5%;
  margin-right: 1.5%;
}

.center-button {
  text-align: center;
}


.call-to-action-container {
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 74px 1000px;
    border-color: transparent transparent #5B6D90 transparent;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  .container {
    position: relative;
    z-index: 2;
  }
}

.feature-box {
  .entry-title,
  .fbox-desc, 
  h2, h3, 
  p {
     text-align: left;
   }
}

.feature-box {
  margin-top: 0;
}

.entry .fbox-desc {
  .entry-date {
    padding-left: 20px;
  }
}

.fbox-desc {
  padding-bottom: 20px;
}


.dark #copyrights {
  text-shadow: none;
  color: #fff;
  background-color: none;
}

aside.section {
  padding: 50px;

  @media all and (max-width: 1023px) {
    padding: 50px 20px;
  }
}

#posts {
  clear: both;
}

.dark .widget_links li a {
  padding-left: 0;
  color: #fff;
}


/* BUTTONS */

.button-secondary{
  background-color: $brand-secondary;
}

.button-frontpage {
  border: 1px solid #89C8BD;
  border-radius: 3px;
  background: none;
  color: #89C8BD;
  position: relative;
  padding-right: 30px;
  font-weight: normal;
  text-shadow: none;
  margin: 20px;
  text-transform: none;

  &:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 11px;
    height: 11px;
    background: url(../images/button-triangle.svg) center center no-repeat;
  }

  &.button-left {
    float: left;
  }
}
.portfolio-desc .button-frontpage {
  margin: 20px 0;
}
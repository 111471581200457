.header_top {
  position: relative;
  margin-top: 20px;
  
  #header, 
  #header-wrap, 
  #logo, 
  #logo img {
    height: auto;
  }

  #header, 
  #header-wrap, 
  #logo img{
    height: auto;

    @media all and (min-width: 1025px) {
      height: 75px;
    }
  }
  .button {
    margin: 0;
    margin-top: 20px;
    font-size: 11px;
    padding: 0 10px;
  }

  @media all and (min-width: 1025px) {
    height: 100px;
  }

  @media all and (max-width: 768px) {
    text-align: center;
    .button {
      margin: 5px 0 10px 0;
      font-size: 12px;
      float: none !important;
    }
  }

  @media all and (min-width: 769px) and (max-width: 1024px) {
    #logo {
      max-width: 455px;
    }
  }
}

#header {
  min-height: 50px;
  height: 50px;
  background: $brand-primary;
  border-bottom: none;

  #header-wrap {
    height: 100%;

  }

  #primary-menu ul li>a {
    color: #fff;
    padding: 0 15px;
    line-height: 50px;

    &:hover {
      background: #fff;
      color: $brand-primary;
    }
  }
}

.icon-reorder {
  color: #fff;
}

#header.dark:not(.transparent-header),
.dark #header:not(.transparent-header) {
  background-color: $brand-primary;
}

#header.sticky-header:not(.static-sticky) #primary-menu>ul>li>a {
  padding: 0 15px;
}

@media all and (max-width: 768px) {
  .nav-primary {
    margin-left: 30px;
  }
}

.primary-menu-open {
  #primary-menu-trigger, 
  #page-submenu-trigger {
    top: 0;
    transition: none !important;
  }
}

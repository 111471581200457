@font-face {
	font-family: 'font-icons';
	src:url('../fonts/font-icons.eot');
	src:url('../fonts/font-icons.eot') format('embedded-opentype'),
		url('../fonts/font-icons.woff') format('woff'),
		url('../fonts/font-icons.ttf') format('truetype'),
		url('../fonts/font-icons.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'lined-icons';
	src:url('../fonts/lined-icons.eot');
	src:url('../fonts/lined-icons.eot') format('embedded-opentype'),
		url('../fonts/lined-icons.woff') format('woff'),
		url('../fonts/lined-icons.ttf') format('truetype'),
		url('../fonts/lined-icons.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Simple-Line-Icons';
	src:url('../fonts/Simple-Line-Icons.eot');
	src:url('../fonts/Simple-Line-Icons.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Simple-Line-Icons.woff') format('woff'),
		url('../fonts/Simple-Line-Icons.ttf') format('truetype'),
		url('../fonts/Simple-Line-Icons.svg#Simple-Line-Icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"],
[class^="icon-line-"], [class*=" icon-line-"],
[class^="icon-line2-"], [class*=" icon-line2-"],
.side-header #primary-menu ul > li.sub-menu > a:after {
	display: inline-block;
	font-family: 'font-icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-line-"], [class*=" icon-line-"] { font-family: 'lined-icons'; }

[class^="icon-line2-"], [class*=" icon-line2-"] { font-family: 'Simple-Line-Icons'; }

.icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.icon-2x {
  font-size: 2em;
}
.icon-3x {
  font-size: 3em;
}
.icon-4x {
  font-size: 4em;
}
.icon-5x {
  font-size: 5em;
}
.icon-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.iconlist {
	padding-left: 0;
	margin-left: 1.75em;
	list-style-type: none;
}

.iconlist li { position: relative; }

.iconlist ul {
	list-style-type: none;
	margin: 5px 0 5px 25px;
}

.iconlist > li [class^="icon-"]:first-child, .iconlist > li [class*=" icon-"]:first-child {
	position: absolute;
	left: -1.75em;
	width: 1.75em;
	text-align: center;
	top: 1px;
	width: 14px;
}

.iconlist.iconlist-large { font-size: 16px; }

.iconlist.iconlist-large li { margin: 4px 0; }

.iconlist.iconlist-large > li [class^="icon-"], .iconlist.iconlist-large > li [class*=" icon-"] {
	width: 16px;
	margin-right: 5px;
}

.iconlist-color li i { color: #1ABC9C; }

.icon-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em;
}
.icon.pull-left {
  margin-right: .3em;
}
.icon.pull-right {
  margin-left: .3em;
}
.icon-spin {
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.icon-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icon-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
.icon-stacked {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.icon-stacked-1x,
.icon-stacked-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.icon-stacked-1x {
  line-height: inherit;
}
.icon-stacked-2x {
  font-size: 2em;
}
.icon-inverse {
  color: #ffffff;
}

.icon-type:before {
	content: "\e600";
}
.icon-box:before {
	content: "\e601";
}
.icon-archive:before {
	content: "\e602";
}
.icon-envelope:before {
	content: "\e603";
}
.icon-email:before {
	content: "\e604";
}
.icon-files:before {
	content: "\e605";
}
.icon-printer2:before {
	content: "\e606";
}
.icon-folder-add:before {
	content: "\e607";
}
.icon-folder-settings:before {
	content: "\e608";
}
.icon-folder-check:before {
	content: "\e609";
}
.icon-wifi-low:before {
	content: "\e60a";
}
.icon-wifi-mid:before {
	content: "\e60b";
}
.icon-wifi-full:before {
	content: "\e60c";
}
.icon-connection-empty:before {
	content: "\e60d";
}
.icon-battery-full:before {
	content: "\e60e";
}
.icon-settings:before {
	content: "\e60f";
}
.icon-arrow-left:before {
	content: "\e610";
}
.icon-arrow-up:before {
	content: "\e611";
}
.icon-arrow-down:before {
	content: "\e612";
}
.icon-arrow-right:before {
	content: "\e613";
}
.icon-reload:before {
	content: "\e614";
}
.icon-download:before {
	content: "\e615";
}
.icon-tag:before {
	content: "\e616";
}
.icon-trashcan:before {
	content: "\e617";
}
.icon-search:before {
	content: "\e618";
}
.icon-zoom-in:before {
	content: "\e619";
}
.icon-zoom-out:before {
	content: "\e61a";
}
.icon-chat:before {
	content: "\e61b";
}
.icon-clock:before {
	content: "\e61c";
}
.icon-printer:before {
	content: "\e61d";
}
.icon-home:before {
	content: "\e61e";
}
.icon-flag:before {
	content: "\e61f";
}
.icon-meter:before {
	content: "\e620";
}
.icon-switch:before {
	content: "\e621";
}
.icon-forbidden:before {
	content: "\e622";
}
.icon-phone-landscape:before {
	content: "\e623";
}
.icon-tablet:before {
	content: "\e624";
}
.icon-tablet-landscape:before {
	content: "\e625";
}
.icon-laptop:before {
	content: "\e626";
}
.icon-camera:before {
	content: "\e627";
}
.icon-microwave-oven:before {
	content: "\e628";
}
.icon-credit-cards:before {
	content: "\e629";
}
.icon-map-marker:before {
	content: "\e62a";
}
.icon-map:before {
	content: "\e62b";
}
.icon-support:before {
	content: "\e62c";
}
.icon-newspaper2:before {
	content: "\e62d";
}
.icon-barbell:before {
	content: "\e62e";
}
.icon-stopwatch:before {
	content: "\e62f";
}
.icon-atom:before {
	content: "\e630";
}
.icon-image:before {
	content: "\e631";
}
.icon-cube:before {
	content: "\e632";
}
.icon-bars:before {
	content: "\e633";
}
.icon-chart:before {
	content: "\e634";
}
.icon-pencil:before {
	content: "\e635";
}
.icon-measure:before {
	content: "\e636";
}
.icon-eyedropper:before {
	content: "\e637";
}
.icon-file-settings:before {
	content: "\e638";
}
.icon-file-add:before {
	content: "\e639";
}
.icon-file:before {
	content: "\e63a";
}
.icon-align-left:before {
	content: "\e63b";
}
.icon-align-right:before {
	content: "\e63c";
}
.icon-align-center:before {
	content: "\e63d";
}
.icon-align-justify:before {
	content: "\e63e";
}
.icon-file-broken:before {
	content: "\e63f";
}
.icon-browser:before {
	content: "\e640";
}
.icon-windows:before {
	content: "\e641";
}
.icon-window:before {
	content: "\e642";
}
.icon-folder:before {
	content: "\e643";
}
.icon-connection-25:before {
	content: "\e644";
}
.icon-connection-50:before {
	content: "\e645";
}
.icon-connection-75:before {
	content: "\e646";
}
.icon-connection-full:before {
	content: "\e647";
}
.icon-list:before {
	content: "\e648";
}
.icon-grid:before {
	content: "\e649";
}
.icon-stack3:before {
	content: "\e64a";
}
.icon-battery-charging:before {
	content: "\e64b";
}
.icon-battery-empty:before {
	content: "\e64c";
}
.icon-battery-25:before {
	content: "\e64d";
}
.icon-battery-50:before {
	content: "\e64e";
}
.icon-battery-75:before {
	content: "\e64f";
}
.icon-refresh:before {
	content: "\e650";
}
.icon-volume:before {
	content: "\e651";
}
.icon-volume-increase:before {
	content: "\e652";
}
.icon-volume-decrease:before {
	content: "\e653";
}
.icon-mute:before {
	content: "\e654";
}
.icon-microphone:before {
	content: "\e655";
}
.icon-microphone-off:before {
	content: "\e656";
}
.icon-book:before {
	content: "\e657";
}
.icon-checkmark:before {
	content: "\e658";
}
.icon-checkbox-checked:before {
	content: "\e659";
}
.icon-checkbox:before {
	content: "\e65a";
}
.icon-paperclip:before {
	content: "\e65b";
}
.icon-chat-1:before {
	content: "\e65c";
}
.icon-chat-2:before {
	content: "\e65d";
}
.icon-chat-3:before {
	content: "\e65e";
}
.icon-comment:before {
	content: "\e65f";
}
.icon-calendar:before {
	content: "\e660";
}
.icon-bookmark:before {
	content: "\e661";
}
.icon-email2:before {
	content: "\e662";
}
.icon-heart:before {
	content: "\e663";
}
.icon-enter:before {
	content: "\e664";
}
.icon-cloud:before {
	content: "\e665";
}
.icon-book2:before {
	content: "\e666";
}
.icon-star:before {
	content: "\e667";
}
.icon-lock:before {
	content: "\e668";
}
.icon-unlocked:before {
	content: "\e669";
}
.icon-unlocked2:before {
	content: "\e66a";
}
.icon-users:before {
	content: "\e66b";
}
.icon-user:before {
	content: "\e66c";
}
.icon-users2:before {
	content: "\e66d";
}
.icon-user2:before {
	content: "\e66e";
}
.icon-bullhorn:before {
	content: "\e66f";
}
.icon-share:before {
	content: "\e670";
}
.icon-screen:before {
	content: "\e671";
}
.icon-phone:before {
	content: "\e672";
}
.icon-phone-portrait:before {
	content: "\e673";
}
.icon-calculator:before {
	content: "\e674";
}
.icon-bag:before {
	content: "\e675";
}
.icon-diamond:before {
	content: "\e676";
}
.icon-drink:before {
	content: "\e677";
}
.icon-shorts:before {
	content: "\e678";
}
.icon-vcard:before {
	content: "\e679";
}
.icon-sun:before {
	content: "\e67a";
}
.icon-bill:before {
	content: "\e67b";
}
.icon-coffee:before {
	content: "\e67c";
}
.icon-tv2:before {
	content: "\e67d";
}
.icon-newspaper:before {
	content: "\e67e";
}
.icon-stack:before {
	content: "\e67f";
}
.icon-syringe:before {
	content: "\e680";
}
.icon-health:before {
	content: "\e681";
}
.icon-bolt:before {
	content: "\e682";
}
.icon-pill:before {
	content: "\e683";
}
.icon-bones:before {
	content: "\e684";
}
.icon-lab:before {
	content: "\e685";
}
.icon-clipboard:before {
	content: "\e686";
}
.icon-mug:before {
	content: "\e687";
}
.icon-bucket:before {
	content: "\e688";
}
.icon-select:before {
	content: "\e689";
}
.icon-graph:before {
	content: "\e68a";
}
.icon-crop:before {
	content: "\e68b";
}
.icon-heart2:before {
	content: "\e68c";
}
.icon-cloud2:before {
	content: "\e68d";
}
.icon-star2:before {
	content: "\e68e";
}
.icon-pen:before {
	content: "\e68f";
}
.icon-diamond2:before {
	content: "\e690";
}
.icon-display:before {
	content: "\e691";
}
.icon-paperplane:before {
	content: "\e692";
}
.icon-params:before {
	content: "\e693";
}
.icon-banknote:before {
	content: "\e694";
}
.icon-vynil:before {
	content: "\e695";
}
.icon-truck:before {
	content: "\e696";
}
.icon-world:before {
	content: "\e697";
}
.icon-tv:before {
	content: "\e698";
}
.icon-sound:before {
	content: "\e699";
}
.icon-video:before {
	content: "\e69a";
}
.icon-trash:before {
	content: "\e69b";
}
.icon-user3:before {
	content: "\e69c";
}
.icon-key:before {
	content: "\e69d";
}
.icon-search2:before {
	content: "\e69e";
}
.icon-settings2:before {
	content: "\e69f";
}
.icon-camera2:before {
	content: "\e6a0";
}
.icon-tag2:before {
	content: "\e6a1";
}
.icon-lock2:before {
	content: "\e6a2";
}
.icon-bulb:before {
	content: "\e6a3";
}
.icon-location:before {
	content: "\e6a4";
}
.icon-eye:before {
	content: "\e6a5";
}
.icon-bubble:before {
	content: "\e6a6";
}
.icon-stack2:before {
	content: "\e6a7";
}
.icon-cup:before {
	content: "\e6a8";
}
.icon-phone2:before {
	content: "\e6a9";
}
.icon-news:before {
	content: "\e6aa";
}
.icon-mail:before {
	content: "\e6ab";
}
.icon-like:before {
	content: "\e6ac";
}
.icon-photo:before {
	content: "\e6ad";
}
.icon-note:before {
	content: "\e6ae";
}
.icon-clock2:before {
	content: "\e6af";
}
.icon-data:before {
	content: "\e6b0";
}
.icon-music:before {
	content: "\e6b1";
}
.icon-megaphone:before {
	content: "\e6b2";
}
.icon-study:before {
	content: "\e6b3";
}
.icon-lab2:before {
	content: "\e6b4";
}
.icon-food:before {
	content: "\e6b5";
}
.icon-t-shirt:before {
	content: "\e6b6";
}
.icon-fire:before {
	content: "\e6b7";
}
.icon-clip:before {
	content: "\e6b8";
}
.icon-shop:before {
	content: "\e6b9";
}
.icon-calendar2:before {
	content: "\e6ba";
}
.icon-wallet:before {
	content: "\e6bb";
}
.icon-duckduckgo:before {
	content: "\e830";
}
.icon-lkdto:before {
	content: "\e896";
}
.icon-delicious:before {
	content: "\e832";
}
.icon-paypal:before {
	content: "\e833";
}
.icon-flattr:before {
	content: "\e834";
}
.icon-android:before {
	content: "\e835";
}
.icon-eventful:before {
	content: "\e836";
}
.icon-smashmag:before {
	content: "\e837";
}
.icon-gplus:before {
	content: "\e838";
}
.icon-wikipedia:before {
	content: "\e839";
}
.icon-lanyrd:before {
	content: "\e83a";
}
.icon-calendar-1:before {
	content: "\e83b";
}
.icon-stumbleupon:before {
	content: "\e83c";
}
.icon-bitcoin:before {
	content: "\e83f";
}
.icon-w3c:before {
	content: "\e840";
}
.icon-foursquare:before {
	content: "\e841";
}
.icon-html5:before {
	content: "\e842";
}
.icon-ie:before {
	content: "\e843";
}
.icon-call:before {
	content: "\e844";
}
.icon-grooveshark:before {
	content: "\e845";
}
.icon-ninetyninedesigns:before {
	content: "\e846";
}
.icon-forrst:before {
	content: "\e847";
}
.icon-digg:before {
	content: "\e848";
}
.icon-spotify:before {
	content: "\e849";
}
.icon-reddit:before {
	content: "\e84a";
}
.icon-guest:before {
	content: "\e84b";
}
.icon-blogger:before {
	content: "\e84e";
}
.icon-cc:before {
	content: "\e84f";
}
.icon-dribbble:before {
	content: "\e850";
}
.icon-evernote:before {
	content: "\e851";
}
.icon-flickr:before {
	content: "\e852";
}
.icon-google:before {
	content: "\e853";
}
.icon-viadeo:before {
	content: "\e854";
}
.icon-instapaper:before {
	content: "\e855";
}
.icon-weibo:before {
	content: "\e856";
}
.icon-klout:before {
	content: "\e857";
}
.icon-linkedin:before {
	content: "\e858";
}
.icon-meetup:before {
	content: "\e859";
}
.icon-vk:before {
	content: "\e85a";
}
.icon-rss:before {
	content: "\e85d";
}
.icon-skype:before {
	content: "\e85e";
}
.icon-twitter:before {
	content: "\e85f";
}
.icon-youtube:before {
	content: "\e860";
}
.icon-vimeo:before {
	content: "\e861";
}
.icon-windows2:before {
	content: "\e862";
}
.icon-aim:before {
	content: "\e831";
}
.icon-yahoo:before {
	content: "\e864";
}
.icon-chrome:before {
	content: "\e865";
}
.icon-email3:before {
	content: "\e866";
}
.icon-macstore:before {
	content: "\e867";
}
.icon-myspace:before {
	content: "\e868";
}
.icon-podcast:before {
	content: "\e869";
}
.icon-cloudapp:before {
	content: "\e86c";
}
.icon-dropbox:before {
	content: "\e86d";
}
.icon-ebay:before {
	content: "\e86e";
}
.icon-facebook:before {
	content: "\e86f";
}
.icon-github:before {
	content: "\e870";
}
.icon-github-circled:before {
	content: "\e871";
}
.icon-googleplay:before {
	content: "\e872";
}
.icon-itunes:before {
	content: "\e873";
}
.icon-plurk:before {
	content: "\e874";
}
.icon-songkick:before {
	content: "\e875";
}
.icon-lastfm:before {
	content: "\e876";
}
.icon-gmail:before {
	content: "\e877";
}
.icon-pinboard:before {
	content: "\e878";
}
.icon-soundcloud:before {
	content: "\e87b";
}
.icon-tumblr:before {
	content: "\e87c";
}
.icon-eventasaurus:before {
	content: "\e87d";
}
.icon-wordpress:before {
	content: "\e87e";
}
.icon-yelp:before {
	content: "\e87f";
}
.icon-intensedebate:before {
	content: "\e880";
}
.icon-eventbrite:before {
	content: "\e881";
}
.icon-scribd:before {
	content: "\e882";
}
.icon-posterous:before {
	content: "\e883";
}
.icon-stripe:before {
	content: "\e884";
}
.icon-opentable:before {
	content: "\e885";
}
.icon-cart:before {
	content: "\e886";
}
.icon-print:before {
	content: "\e887";
}
.icon-dwolla:before {
	content: "\e88a";
}
.icon-appnet:before {
	content: "\e88b";
}
.icon-statusnet:before {
	content: "\e88c";
}
.icon-acrobat:before {
	content: "\e88d";
}
.icon-drupal:before {
	content: "\e88e";
}
.icon-buffer:before {
	content: "\e88f";
}
.icon-pocket:before {
	content: "\e890";
}
.icon-bitbucket:before {
	content: "\e891";
}
.icon-lego:before {
	content: "\e892";
}
.icon-login:before {
	content: "\e893";
}
.icon-stackoverflow:before {
	content: "\e894";
}
.icon-hackernews:before {
	content: "\e895";
}
.icon-xing:before {
	content: "\e863";
}
.icon-instagram:before {
	content: "\e889";
}
.icon-angellist:before {
	content: "\e888";
}
.icon-quora:before {
	content: "\e87a";
}
.icon-openid:before {
	content: "\e879";
}
.icon-steam:before {
	content: "\e86b";
}
.icon-amazon:before {
	content: "\e86a";
}
.icon-disqus:before {
	content: "\e85c";
}
.icon-plancast:before {
	content: "\e85b";
}
.icon-appstore:before {
	content: "\e84d";
}
.icon-gowalla:before {
	content: "\e84c";
}
.icon-pinterest:before {
	content: "\e83e";
}
.icon-fivehundredpx:before {
	content: "\e83d";
}
.icon-glass:before {
	content: "\e6bc";
}
.icon-music2:before {
	content: "\e6bd";
}
.icon-search3:before {
	content: "\e6be";
}
.icon-envelope2:before {
	content: "\e6bf";
}
.icon-heart3:before {
	content: "\e6c0";
}
.icon-star3:before {
	content: "\e6c1";
}
.icon-star-empty:before {
	content: "\e6c2";
}
.icon-user4:before {
	content: "\e6c3";
}
.icon-film:before {
	content: "\e6c4";
}
.icon-th-large:before {
	content: "\e6c5";
}
.icon-th:before {
	content: "\e6c6";
}
.icon-th-list:before {
	content: "\e6c7";
}
.icon-ok:before {
	content: "\e6c8";
}
.icon-remove:before {
	content: "\e6c9";
}
.icon-zoom-in2:before {
	content: "\e6ca";
}
.icon-zoom-out2:before {
	content: "\e6cb";
}
.icon-off:before {
	content: "\e6cc";
}
.icon-signal:before {
	content: "\e6cd";
}
.icon-cog:before {
	content: "\e6ce";
}
.icon-trash2:before {
	content: "\e6cf";
}
.icon-home2:before {
	content: "\e6d0";
}
.icon-file2:before {
	content: "\e6d1";
}
.icon-time:before {
	content: "\e6d2";
}
.icon-road:before {
	content: "\e6d3";
}
.icon-download-alt:before {
	content: "\e6d4";
}
.icon-download2:before {
	content: "\e6d5";
}
.icon-upload:before {
	content: "\e6d6";
}
.icon-inbox:before {
	content: "\e6d7";
}
.icon-play-circle:before {
	content: "\e6d8";
}
.icon-repeat:before {
	content: "\e6d9";
}
.icon-refresh2:before {
	content: "\e6da";
}
.icon-list-alt:before {
	content: "\e6db";
}
.icon-lock3:before {
	content: "\e6dc";
}
.icon-flag2:before {
	content: "\e6dd";
}
.icon-headphones:before {
	content: "\e6de";
}
.icon-volume-off:before {
	content: "\e6df";
}
.icon-volume-down:before {
	content: "\e6e0";
}
.icon-volume-up:before {
	content: "\e6e1";
}
.icon-qrcode:before {
	content: "\e6e2";
}
.icon-barcode:before {
	content: "\e6e3";
}
.icon-tag3:before {
	content: "\e6e4";
}
.icon-tags:before {
	content: "\e6e5";
}
.icon-book3:before {
	content: "\e6e6";
}
.icon-bookmark2:before {
	content: "\e6e7";
}
.icon-print2:before {
	content: "\e6e8";
}
.icon-camera3:before {
	content: "\e6e9";
}
.icon-font:before {
	content: "\e6ea";
}
.icon-bold:before {
	content: "\e6eb";
}
.icon-italic:before {
	content: "\e6ec";
}
.icon-text-height:before {
	content: "\e6ed";
}
.icon-text-width:before {
	content: "\e6ee";
}
.icon-align-left2:before {
	content: "\e6ef";
}
.icon-align-center2:before {
	content: "\e6f0";
}
.icon-align-right2:before {
	content: "\e6f1";
}
.icon-align-justify2:before {
	content: "\e6f2";
}
.icon-list2:before {
	content: "\e6f3";
}
.icon-indent-left:before {
	content: "\e6f4";
}
.icon-indent-right:before {
	content: "\e6f5";
}
.icon-facetime-video:before {
	content: "\e6f6";
}
.icon-picture:before {
	content: "\e6f7";
}
.icon-pencil2:before {
	content: "\e6f8";
}
.icon-map-marker2:before {
	content: "\e6f9";
}
.icon-adjust:before {
	content: "\e6fa";
}
.icon-tint:before {
	content: "\e6fb";
}
.icon-edit:before {
	content: "\e6fc";
}
.icon-share2:before {
	content: "\e6fd";
}
.icon-check:before {
	content: "\e6fe";
}
.icon-move:before {
	content: "\e6ff";
}
.icon-step-backward:before {
	content: "\e700";
}
.icon-fast-backward:before {
	content: "\e701";
}
.icon-backward:before {
	content: "\e702";
}
.icon-play:before {
	content: "\e703";
}
.icon-pause:before {
	content: "\e704";
}
.icon-stop:before {
	content: "\e705";
}
.icon-forward:before {
	content: "\e706";
}
.icon-fast-forward:before {
	content: "\e707";
}
.icon-step-forward:before {
	content: "\e708";
}
.icon-eject:before {
	content: "\e709";
}
.icon-chevron-left:before {
	content: "\e70a";
}
.icon-chevron-right:before {
	content: "\e70b";
}
.icon-plus-sign:before {
	content: "\e70c";
}
.icon-minus-sign:before {
	content: "\e70d";
}
.icon-remove-sign:before {
	content: "\e70e";
}
.icon-ok-sign:before {
	content: "\e70f";
}
.icon-question-sign:before {
	content: "\e710";
}
.icon-info-sign:before {
	content: "\e711";
}
.icon-screenshot:before {
	content: "\e712";
}
.icon-remove-circle:before {
	content: "\e713";
}
.icon-ok-circle:before {
	content: "\e714";
}
.icon-ban-circle:before {
	content: "\e715";
}
.icon-arrow-left2:before {
	content: "\e716";
}
.icon-arrow-right2:before {
	content: "\e717";
}
.icon-arrow-up2:before {
	content: "\e718";
}
.icon-arrow-down2:before {
	content: "\e719";
}
.icon-share-alt:before {
	content: "\e71a";
}
.icon-resize-full:before {
	content: "\e71b";
}
.icon-resize-small:before {
	content: "\e71c";
}
.icon-plus:before {
	content: "\e71d";
}
.icon-minus:before {
	content: "\e71e";
}
.icon-asterisk:before {
	content: "\e71f";
}
.icon-exclamation-sign:before {
	content: "\e720";
}
.icon-gift:before {
	content: "\e721";
}
.icon-leaf:before {
	content: "\e722";
}
.icon-fire2:before {
	content: "\e723";
}
.icon-eye-open:before {
	content: "\e724";
}
.icon-eye-close:before {
	content: "\e725";
}
.icon-warning-sign:before {
	content: "\e726";
}
.icon-plane:before {
	content: "\e727";
}
.icon-calendar3:before {
	content: "\e728";
}
.icon-random:before {
	content: "\e729";
}
.icon-comment2:before {
	content: "\e72a";
}
.icon-magnet:before {
	content: "\e72b";
}
.icon-chevron-up:before {
	content: "\e72c";
}
.icon-chevron-down:before {
	content: "\e72d";
}
.icon-retweet:before {
	content: "\e72e";
}
.icon-shopping-cart:before {
	content: "\e72f";
}
.icon-folder-close:before {
	content: "\e730";
}
.icon-folder-open:before {
	content: "\e731";
}
.icon-resize-vertical:before {
	content: "\e732";
}
.icon-resize-horizontal:before {
	content: "\e733";
}
.icon-bar-chart:before {
	content: "\e734";
}
.icon-twitter-sign:before {
	content: "\e735";
}
.icon-facebook-sign:before {
	content: "\e736";
}
.icon-camera-retro:before {
	content: "\e737";
}
.icon-key2:before {
	content: "\e738";
}
.icon-cogs:before {
	content: "\e739";
}
.icon-comments:before {
	content: "\e73a";
}
.icon-thumbs-up:before {
	content: "\e73b";
}
.icon-thumbs-down:before {
	content: "\e73c";
}
.icon-star-half:before {
	content: "\e73d";
}
.icon-heart-empty:before {
	content: "\e73e";
}
.icon-signout:before {
	content: "\e73f";
}
.icon-linkedin-sign:before {
	content: "\e740";
}
.icon-pushpin:before {
	content: "\e741";
}
.icon-external-link:before {
	content: "\e742";
}
.icon-signin:before {
	content: "\e743";
}
.icon-trophy:before {
	content: "\e744";
}
.icon-github-sign:before {
	content: "\e745";
}
.icon-upload-alt:before {
	content: "\e746";
}
.icon-lemon:before {
	content: "\e747";
}
.icon-phone3:before {
	content: "\e748";
}
.icon-check-empty:before {
	content: "\e749";
}
.icon-bookmark-empty:before {
	content: "\e74a";
}
.icon-phone-sign:before {
	content: "\e74b";
}
.icon-twitter2:before {
	content: "\e74c";
}
.icon-facebook2:before {
	content: "\e74d";
}
.icon-github2:before {
	content: "\e74e";
}
.icon-unlock:before {
	content: "\e74f";
}
.icon-credit:before {
	content: "\e750";
}
.icon-rss2:before {
	content: "\e751";
}
.icon-hdd:before {
	content: "\e752";
}
.icon-bullhorn2:before {
	content: "\e753";
}
.icon-bell:before {
	content: "\e754";
}
.icon-certificate:before {
	content: "\e755";
}
.icon-hand-right:before {
	content: "\e756";
}
.icon-hand-left:before {
	content: "\e757";
}
.icon-hand-up:before {
	content: "\e758";
}
.icon-hand-down:before {
	content: "\e759";
}
.icon-circle-arrow-left:before {
	content: "\e75a";
}
.icon-circle-arrow-right:before {
	content: "\e75b";
}
.icon-circle-arrow-up:before {
	content: "\e75c";
}
.icon-circle-arrow-down:before {
	content: "\e75d";
}
.icon-globe:before {
	content: "\e75e";
}
.icon-wrench:before {
	content: "\e75f";
}
.icon-tasks:before {
	content: "\e760";
}
.icon-filter:before {
	content: "\e761";
}
.icon-briefcase:before {
	content: "\e762";
}
.icon-fullscreen:before {
	content: "\e763";
}
.icon-group:before {
	content: "\e764";
}
.icon-link:before {
	content: "\e765";
}
.icon-cloud3:before {
	content: "\e766";
}
.icon-beaker:before {
	content: "\e767";
}
.icon-cut:before {
	content: "\e768";
}
.icon-copy:before {
	content: "\e769";
}
.icon-paper-clip:before {
	content: "\e76a";
}
.icon-save:before {
	content: "\e76b";
}
.icon-sign-blank:before {
	content: "\e76c";
}
.icon-reorder:before {
	content: "\e76d";
}
.icon-list-ul:before {
	content: "\e76e";
}
.icon-list-ol:before {
	content: "\e76f";
}
.icon-strikethrough:before {
	content: "\e770";
}
.icon-underline:before {
	content: "\e771";
}
.icon-table:before {
	content: "\e772";
}
.icon-magic:before {
	content: "\e773";
}
.icon-truck2:before {
	content: "\e774";
}
.icon-pinterest2:before {
	content: "\e775";
}
.icon-pinterest-sign:before {
	content: "\e776";
}
.icon-google-plus-sign:before {
	content: "\e777";
}
.icon-google-plus:before {
	content: "\e778";
}
.icon-money:before {
	content: "\e779";
}
.icon-caret-down:before {
	content: "\e77a";
}
.icon-caret-up:before {
	content: "\e77b";
}
.icon-caret-left:before {
	content: "\e77c";
}
.icon-caret-right:before {
	content: "\e77d";
}
.icon-columns:before {
	content: "\e77e";
}
.icon-sort:before {
	content: "\e77f";
}
.icon-sort-down:before {
	content: "\e780";
}
.icon-sort-up:before {
	content: "\e781";
}
.icon-envelope-alt:before {
	content: "\e782";
}
.icon-linkedin2:before {
	content: "\e783";
}
.icon-undo:before {
	content: "\e784";
}
.icon-legal:before {
	content: "\e785";
}
.icon-dashboard:before {
	content: "\e786";
}
.icon-comment-alt:before {
	content: "\e787";
}
.icon-comments-alt:before {
	content: "\e788";
}
.icon-bolt2:before {
	content: "\e789";
}
.icon-sitemap:before {
	content: "\e78a";
}
.icon-umbrella:before {
	content: "\e78b";
}
.icon-paste:before {
	content: "\e78c";
}
.icon-lightbulb:before {
	content: "\e78d";
}
.icon-exchange:before {
	content: "\e78e";
}
.icon-cloud-download:before {
	content: "\e78f";
}
.icon-cloud-upload:before {
	content: "\e790";
}
.icon-user-md:before {
	content: "\e791";
}
.icon-stethoscope:before {
	content: "\e792";
}
.icon-suitcase:before {
	content: "\e793";
}
.icon-bell-alt:before {
	content: "\e794";
}
.icon-coffee2:before {
	content: "\e795";
}
.icon-food2:before {
	content: "\e796";
}
.icon-file-alt:before {
	content: "\e797";
}
.icon-building:before {
	content: "\e798";
}
.icon-hospital:before {
	content: "\e799";
}
.icon-ambulance:before {
	content: "\e79a";
}
.icon-medkit:before {
	content: "\e79b";
}
.icon-fighter-jet:before {
	content: "\e79c";
}
.icon-beer:before {
	content: "\e79d";
}
.icon-h-sign:before {
	content: "\e79e";
}
.icon-plus-sign2:before {
	content: "\e79f";
}
.icon-double-angle-left:before {
	content: "\e7a0";
}
.icon-double-angle-right:before {
	content: "\e7a1";
}
.icon-double-angle-up:before {
	content: "\e7a2";
}
.icon-double-angle-down:before {
	content: "\e7a3";
}
.icon-angle-left:before {
	content: "\e7a4";
}
.icon-angle-right:before {
	content: "\e7a5";
}
.icon-angle-up:before {
	content: "\e7a6";
}
.icon-angle-down:before {
	content: "\e7a7";
}
.icon-desktop:before {
	content: "\e7a8";
}
.icon-laptop2:before {
	content: "\e7a9";
}
.icon-tablet2:before {
	content: "\e7aa";
}
.icon-mobile:before {
	content: "\e7ab";
}
.icon-circle-blank:before {
	content: "\e7ac";
}
.icon-quote-left:before {
	content: "\e7ad";
}
.icon-quote-right:before {
	content: "\e7ae";
}
.icon-spinner:before {
	content: "\e7af";
}
.icon-circle:before {
	content: "\e7b0";
}
.icon-reply:before {
	content: "\e7b1";
}
.icon-github-alt:before {
	content: "\e7b2";
}
.icon-folder-close-alt:before {
	content: "\e7b3";
}
.icon-folder-open-alt:before {
	content: "\e7b4";
}
.icon-expand-alt:before {
	content: "\e7b5";
}
.icon-collapse-alt:before {
	content: "\e7b6";
}
.icon-smile:before {
	content: "\e7b7";
}
.icon-frown:before {
	content: "\e7b8";
}
.icon-meh:before {
	content: "\e7b9";
}
.icon-gamepad:before {
	content: "\e7ba";
}
.icon-keyboard:before {
	content: "\e7bb";
}
.icon-flag-alt:before {
	content: "\e7bc";
}
.icon-flag-checkered:before {
	content: "\e7bd";
}
.icon-terminal:before {
	content: "\e7be";
}
.icon-code:before {
	content: "\e7bf";
}
.icon-reply-all:before {
	content: "\e7c0";
}
.icon-star-half-full:before {
	content: "\e7c1";
}
.icon-location-arrow:before {
	content: "\e7c2";
}
.icon-crop2:before {
	content: "\e7c3";
}
.icon-code-fork:before {
	content: "\e7c4";
}
.icon-unlink:before {
	content: "\e7c5";
}
.icon-question:before {
	content: "\e7c6";
}
.icon-info:before {
	content: "\e7c7";
}
.icon-exclamation:before {
	content: "\e7c8";
}
.icon-superscript:before {
	content: "\e7c9";
}
.icon-subscript:before {
	content: "\e7ca";
}
.icon-eraser:before {
	content: "\e7cb";
}
.icon-puzzle:before {
	content: "\e7cc";
}
.icon-microphone2:before {
	content: "\e7cd";
}
.icon-microphone-off2:before {
	content: "\e7ce";
}
.icon-shield:before {
	content: "\e7cf";
}
.icon-calendar-empty:before {
	content: "\e7d0";
}
.icon-fire-extinguisher:before {
	content: "\e7d1";
}
.icon-rocket:before {
	content: "\e7d2";
}
.icon-maxcdn:before {
	content: "\e7d3";
}
.icon-chevron-sign-left:before {
	content: "\e7d4";
}
.icon-chevron-sign-right:before {
	content: "\e7d5";
}
.icon-chevron-sign-up:before {
	content: "\e7d6";
}
.icon-chevron-sign-down:before {
	content: "\e7d7";
}
.icon-html52:before {
	content: "\e7d8";
}
.icon-css3:before {
	content: "\e7d9";
}
.icon-anchor:before {
	content: "\e7da";
}
.icon-unlock-alt:before {
	content: "\e7db";
}
.icon-bullseye:before {
	content: "\e7dc";
}
.icon-ellipsis-horizontal:before {
	content: "\e7dd";
}
.icon-ellipsis-vertical:before {
	content: "\e7de";
}
.icon-rss-sign:before {
	content: "\e7df";
}
.icon-play-sign:before {
	content: "\e7e0";
}
.icon-ticket:before {
	content: "\e7e1";
}
.icon-minus-sign-alt:before {
	content: "\e7e2";
}
.icon-check-minus:before {
	content: "\e7e3";
}
.icon-level-up:before {
	content: "\e7e4";
}
.icon-level-down:before {
	content: "\e7e5";
}
.icon-check-sign:before {
	content: "\e7e6";
}
.icon-edit-sign:before {
	content: "\e7e7";
}
.icon-external-link-sign:before {
	content: "\e7e8";
}
.icon-share-sign:before {
	content: "\e7e9";
}
.icon-compass:before {
	content: "\e7ea";
}
.icon-collapse:before {
	content: "\e7eb";
}
.icon-collapse-top:before {
	content: "\e7ec";
}
.icon-expand:before {
	content: "\e7ed";
}
.icon-euro:before {
	content: "\e7ee";
}
.icon-gbp:before {
	content: "\e7ef";
}
.icon-dollar:before {
	content: "\e7f0";
}
.icon-rupee:before {
	content: "\e7f1";
}
.icon-yen:before {
	content: "\e7f2";
}
.icon-renminbi:before {
	content: "\e7f3";
}
.icon-won:before {
	content: "\e7f4";
}
.icon-bitcoin2:before {
	content: "\e7f5";
}
.icon-file3:before {
	content: "\e7f6";
}
.icon-file-text:before {
	content: "\e7f7";
}
.icon-sort-by-alphabet:before {
	content: "\e7f8";
}
.icon-sort-by-alphabet-alt:before {
	content: "\e7f9";
}
.icon-sort-by-attributes:before {
	content: "\e7fa";
}
.icon-sort-by-attributes-alt:before {
	content: "\e7fb";
}
.icon-sort-by-order:before {
	content: "\e7fc";
}
.icon-sort-by-order-alt:before {
	content: "\e7fd";
}
.icon-thumbs-up2:before {
	content: "\e7fe";
}
.icon-thumbs-down2:before {
	content: "\e7ff";
}
.icon-youtube-sign:before {
	content: "\e800";
}
.icon-youtube2:before {
	content: "\e801";
}
.icon-xing2:before {
	content: "\e802";
}
.icon-xing-sign:before {
	content: "\e803";
}
.icon-youtube-play:before {
	content: "\e804";
}
.icon-dropbox2:before {
	content: "\e805";
}
.icon-stackexchange:before {
	content: "\e806";
}
.icon-instagram2:before {
	content: "\e807";
}
.icon-flickr2:before {
	content: "\e808";
}
.icon-adn:before {
	content: "\e809";
}
.icon-bitbucket2:before {
	content: "\e80a";
}
.icon-bitbucket-sign:before {
	content: "\e80b";
}
.icon-tumblr2:before {
	content: "\e80c";
}
.icon-tumblr-sign:before {
	content: "\e80d";
}
.icon-long-arrow-down:before {
	content: "\e80e";
}
.icon-long-arrow-up:before {
	content: "\e80f";
}
.icon-long-arrow-left:before {
	content: "\e810";
}
.icon-long-arrow-right:before {
	content: "\e811";
}
.icon-apple:before {
	content: "\e812";
}
.icon-windows3:before {
	content: "\e813";
}
.icon-android2:before {
	content: "\e814";
}
.icon-linux:before {
	content: "\e815";
}
.icon-dribbble2:before {
	content: "\e816";
}
.icon-skype2:before {
	content: "\e817";
}
.icon-foursquare2:before {
	content: "\e818";
}
.icon-trello:before {
	content: "\e819";
}
.icon-female:before {
	content: "\e81a";
}
.icon-male:before {
	content: "\e81b";
}
.icon-gittip:before {
	content: "\e81c";
}
.icon-sun2:before {
	content: "\e81d";
}
.icon-moon:before {
	content: "\e81e";
}
.icon-archive2:before {
	content: "\e81f";
}
.icon-bug:before {
	content: "\e820";
}
.icon-renren:before {
	content: "\e821";
}
.icon-weibo2:before {
	content: "\e822";
}
.icon-vk2:before {
	content: "\e823";
}

.icon-line-eye:before {
	content: "\e000";
}
.icon-line-paper-clip:before {
	content: "\e001";
}
.icon-line-mail:before {
	content: "\e002";
}
.icon-line-toggle:before {
	content: "\e003";
}
.icon-line-layout:before {
	content: "\e004";
}
.icon-line-link:before {
	content: "\e005";
}
.icon-line-bell:before {
	content: "\e006";
}
.icon-line-lock:before {
	content: "\e007";
}
.icon-line-unlock:before {
	content: "\e008";
}
.icon-line-ribbon:before {
	content: "\e009";
}
.icon-line-image:before {
	content: "\e010";
}
.icon-line-signal:before {
	content: "\e011";
}
.icon-line-target:before {
	content: "\e012";
}
.icon-line-clipboard:before {
	content: "\e013";
}
.icon-line-clock:before {
	content: "\e014";
}
.icon-line-watch:before {
	content: "\e015";
}
.icon-line-air-play:before {
	content: "\e016";
}
.icon-line-camera:before {
	content: "\e017";
}
.icon-line-video:before {
	content: "\e018";
}
.icon-line-disc:before {
	content: "\e019";
}
.icon-line-printer:before {
	content: "\e020";
}
.icon-line-monitor:before {
	content: "\e021";
}
.icon-line-server:before {
	content: "\e022";
}
.icon-line-cog:before {
	content: "\e023";
}
.icon-line-heart:before {
	content: "\e024";
}
.icon-line-paragraph:before {
	content: "\e025";
}
.icon-line-align-justify:before {
	content: "\e026";
}
.icon-line-align-left:before {
	content: "\e027";
}
.icon-line-align-center:before {
	content: "\e028";
}
.icon-line-align-right:before {
	content: "\e029";
}
.icon-line-book:before {
	content: "\e030";
}
.icon-line-layers:before {
	content: "\e031";
}
.icon-line-stack:before {
	content: "\e032";
}
.icon-line-stack-2:before {
	content: "\e033";
}
.icon-line-paper:before {
	content: "\e034";
}
.icon-line-paper-stack:before {
	content: "\e035";
}
.icon-line-search:before {
	content: "\e036";
}
.icon-line-zoom-in:before {
	content: "\e037";
}
.icon-line-zoom-out:before {
	content: "\e038";
}
.icon-line-reply:before {
	content: "\e039";
}
.icon-line-circle-plus:before {
	content: "\e040";
}
.icon-line-circle-minus:before {
	content: "\e041";
}
.icon-line-circle-check:before {
	content: "\e042";
}
.icon-line-circle-cross:before {
	content: "\e043";
}
.icon-line-square-plus:before {
	content: "\e044";
}
.icon-line-square-minus:before {
	content: "\e045";
}
.icon-line-square-check:before {
	content: "\e046";
}
.icon-line-square-cross:before {
	content: "\e047";
}
.icon-line-microphone:before {
	content: "\e048";
}
.icon-line-record:before {
	content: "\e049";
}
.icon-line-skip-back:before {
	content: "\e050";
}
.icon-line-rewind:before {
	content: "\e051";
}
.icon-line-play:before {
	content: "\e052";
}
.icon-line-pause:before {
	content: "\e053";
}
.icon-line-stop:before {
	content: "\e054";
}
.icon-line-fast-forward:before {
	content: "\e055";
}
.icon-line-skip-forward:before {
	content: "\e056";
}
.icon-line-shuffle:before {
	content: "\e057";
}
.icon-line-repeat:before {
	content: "\e058";
}
.icon-line-folder:before {
	content: "\e059";
}
.icon-line-umbrella:before {
	content: "\e060";
}
.icon-line-moon:before {
	content: "\e061";
}
.icon-line-thermometer:before {
	content: "\e062";
}
.icon-line-drop:before {
	content: "\e063";
}
.icon-line-sun:before {
	content: "\e064";
}
.icon-line-cloud:before {
	content: "\e065";
}
.icon-line-cloud-upload:before {
	content: "\e066";
}
.icon-line-cloud-download:before {
	content: "\e067";
}
.icon-line-upload:before {
	content: "\e068";
}
.icon-line-download:before {
	content: "\e069";
}
.icon-line-location:before {
	content: "\e070";
}
.icon-line-location-2:before {
	content: "\e071";
}
.icon-line-map:before {
	content: "\e072";
}
.icon-line-battery:before {
	content: "\e073";
}
.icon-line-head:before {
	content: "\e074";
}
.icon-line-briefcase:before {
	content: "\e075";
}
.icon-line-speech-bubble:before {
	content: "\e076";
}
.icon-line-anchor:before {
	content: "\e077";
}
.icon-line-globe:before {
	content: "\e078";
}
.icon-line-box:before {
	content: "\e079";
}
.icon-line-reload:before {
	content: "\e080";
}
.icon-line-share:before {
	content: "\e081";
}
.icon-line-marquee:before {
	content: "\e082";
}
.icon-line-marquee-plus:before {
	content: "\e083";
}
.icon-line-marquee-minus:before {
	content: "\e084";
}
.icon-line-tag:before {
	content: "\e085";
}
.icon-line-power:before {
	content: "\e086";
}
.icon-line-command:before {
	content: "\e087";
}
.icon-line-alt:before {
	content: "\e088";
}
.icon-line-esc:before {
	content: "\e089";
}
.icon-line-bar-graph:before {
	content: "\e090";
}
.icon-line-bar-graph-2:before {
	content: "\e091";
}
.icon-line-pie-graph:before {
	content: "\e092";
}
.icon-line-star:before {
	content: "\e093";
}
.icon-line-arrow-left:before {
	content: "\e094";
}
.icon-line-arrow-right:before {
	content: "\e095";
}
.icon-line-arrow-up:before {
	content: "\e096";
}
.icon-line-arrow-down:before {
	content: "\e097";
}
.icon-line-volume:before {
	content: "\e098";
}
.icon-line-mute:before {
	content: "\e099";
}
.icon-line-content-right:before {
	content: "\e100";
}
.icon-line-content-left:before {
	content: "\e101";
}
.icon-line-grid:before {
	content: "\e102";
}
.icon-line-grid-2:before {
	content: "\e103";
}
.icon-line-columns:before {
	content: "\e104";
}
.icon-line-loader:before {
	content: "\e105";
}
.icon-line-bag:before {
	content: "\e106";
}
.icon-line-ban:before {
	content: "\e107";
}
.icon-line-flag:before {
	content: "\e108";
}
.icon-line-trash:before {
	content: "\e109";
}
.icon-line-expand:before {
	content: "\e110";
}
.icon-line-contract:before {
	content: "\e111";
}
.icon-line-maximize:before {
	content: "\e112";
}
.icon-line-minimize:before {
	content: "\e113";
}
.icon-line-plus:before {
	content: "\e114";
}
.icon-line-minus:before {
	content: "\e115";
}
.icon-line-check:before {
	content: "\e116";
}
.icon-line-cross:before {
	content: "\e117";
}
.icon-line-move:before {
	content: "\e118";
}
.icon-line-delete:before {
	content: "\e119";
}
.icon-line-menu:before {
	content: "\e120";
}
.icon-line-archive:before {
	content: "\e121";
}
.icon-line-inbox:before {
	content: "\e122";
}
.icon-line-outbox:before {
	content: "\e123";
}
.icon-line-file:before {
	content: "\e124";
}
.icon-line-file-add:before {
	content: "\e125";
}
.icon-line-file-subtract:before {
	content: "\e126";
}
.icon-line-help:before {
	content: "\e127";
}
.icon-line-open:before {
	content: "\e128";
}
.icon-line-ellipsis:before {
	content: "\e129";
}

.icon-line2-user-female:before {
	content: "\e000";
}
.icon-line2-user-follow:before {
	content: "\e002";
}
.icon-line2-user-following:before {
	content: "\e003";
}
.icon-line2-user-unfollow:before {
	content: "\e004";
}
.icon-line2-trophy:before {
	content: "\e006";
}
.icon-line2-screen-smartphone:before {
	content: "\e010";
}
.icon-line2-screen-desktop:before {
	content: "\e011";
}
.icon-line2-plane:before {
	content: "\e012";
}
.icon-line2-notebook:before {
	content: "\e013";
}
.icon-line2-moustache:before {
	content: "\e014";
}
.icon-line2-mouse:before {
	content: "\e015";
}
.icon-line2-magnet:before {
	content: "\e016";
}
.icon-line2-energy:before {
	content: "\e020";
}
.icon-line2-emoticon-smile:before {
	content: "\e021";
}
.icon-line2-disc:before {
	content: "\e022";
}
.icon-line2-cursor-move:before {
	content: "\e023";
}
.icon-line2-crop:before {
	content: "\e024";
}
.icon-line2-credit-card:before {
	content: "\e025";
}
.icon-line2-chemistry:before {
	content: "\e026";
}
.icon-line2-user:before {
	content: "\e005";
}
.icon-line2-speedometer:before {
	content: "\e007";
}
.icon-line2-social-youtube:before {
	content: "\e008";
}
.icon-line2-social-twitter:before {
	content: "\e009";
}
.icon-line2-social-tumblr:before {
	content: "\e00a";
}
.icon-line2-social-facebook:before {
	content: "\e00b";
}
.icon-line2-social-dropbox:before {
	content: "\e00c";
}
.icon-line2-social-dribbble:before {
	content: "\e00d";
}
.icon-line2-shield:before {
	content: "\e00e";
}
.icon-line2-screen-tablet:before {
	content: "\e00f";
}
.icon-line2-magic-wand:before {
	content: "\e017";
}
.icon-line2-hourglass:before {
	content: "\e018";
}
.icon-line2-graduation:before {
	content: "\e019";
}
.icon-line2-ghost:before {
	content: "\e01a";
}
.icon-line2-game-controller:before {
	content: "\e01b";
}
.icon-line2-fire:before {
	content: "\e01c";
}
.icon-line2-eyeglasses:before {
	content: "\e01d";
}
.icon-line2-envelope-open:before {
	content: "\e01e";
}
.icon-line2-envelope-letter:before {
	content: "\e01f";
}
.icon-line2-bell:before {
	content: "\e027";
}
.icon-line2-badge:before {
	content: "\e028";
}
.icon-line2-anchor:before {
	content: "\e029";
}
.icon-line2-wallet:before {
	content: "\e02a";
}
.icon-line2-vector:before {
	content: "\e02b";
}
.icon-line2-speech:before {
	content: "\e02c";
}
.icon-line2-puzzle:before {
	content: "\e02d";
}
.icon-line2-printer:before {
	content: "\e02e";
}
.icon-line2-present:before {
	content: "\e02f";
}
.icon-line2-playlist:before {
	content: "\e030";
}
.icon-line2-pin:before {
	content: "\e031";
}
.icon-line2-picture:before {
	content: "\e032";
}
.icon-line2-map:before {
	content: "\e033";
}
.icon-line2-layers:before {
	content: "\e034";
}
.icon-line2-handbag:before {
	content: "\e035";
}
.icon-line2-globe-alt:before {
	content: "\e036";
}
.icon-line2-globe:before {
	content: "\e037";
}
.icon-line2-frame:before {
	content: "\e038";
}
.icon-line2-folder-alt:before {
	content: "\e039";
}
.icon-line2-film:before {
	content: "\e03a";
}
.icon-line2-feed:before {
	content: "\e03b";
}
.icon-line2-earphones-alt:before {
	content: "\e03c";
}
.icon-line2-earphones:before {
	content: "\e03d";
}
.icon-line2-drop:before {
	content: "\e03e";
}
.icon-line2-drawer:before {
	content: "\e03f";
}
.icon-line2-docs:before {
	content: "\e040";
}
.icon-line2-directions:before {
	content: "\e041";
}
.icon-line2-direction:before {
	content: "\e042";
}
.icon-line2-diamond:before {
	content: "\e043";
}
.icon-line2-cup:before {
	content: "\e044";
}
.icon-line2-compass:before {
	content: "\e045";
}
.icon-line2-call-out:before {
	content: "\e046";
}
.icon-line2-call-in:before {
	content: "\e047";
}
.icon-line2-call-end:before {
	content: "\e048";
}
.icon-line2-calculator:before {
	content: "\e049";
}
.icon-line2-bubbles:before {
	content: "\e04a";
}
.icon-line2-briefcase:before {
	content: "\e04b";
}
.icon-line2-book-open:before {
	content: "\e04c";
}
.icon-line2-basket-loaded:before {
	content: "\e04d";
}
.icon-line2-basket:before {
	content: "\e04e";
}
.icon-line2-bag:before {
	content: "\e04f";
}
.icon-line2-action-undo:before {
	content: "\e050";
}
.icon-line2-action-redo:before {
	content: "\e051";
}
.icon-line2-wrench:before {
	content: "\e052";
}
.icon-line2-umbrella:before {
	content: "\e053";
}
.icon-line2-trash:before {
	content: "\e054";
}
.icon-line2-tag:before {
	content: "\e055";
}
.icon-line2-support:before {
	content: "\e056";
}
.icon-line2-size-fullscreen:before {
	content: "\e057";
}
.icon-line2-size-actual:before {
	content: "\e058";
}
.icon-line2-shuffle:before {
	content: "\e059";
}
.icon-line2-share-alt:before {
	content: "\e05a";
}
.icon-line2-share:before {
	content: "\e05b";
}
.icon-line2-rocket:before {
	content: "\e05c";
}
.icon-line2-question:before {
	content: "\e05d";
}
.icon-line2-pie-chart:before {
	content: "\e05e";
}
.icon-line2-pencil:before {
	content: "\e05f";
}
.icon-line2-note:before {
	content: "\e060";
}
.icon-line2-music-tone-alt:before {
	content: "\e061";
}
.icon-line2-music-tone:before {
	content: "\e062";
}
.icon-line2-microphone:before {
	content: "\e063";
}
.icon-line2-loop:before {
	content: "\e064";
}
.icon-line2-logout:before {
	content: "\e065";
}
.icon-line2-login:before {
	content: "\e066";
}
.icon-line2-list:before {
	content: "\e067";
}
.icon-line2-like:before {
	content: "\e068";
}
.icon-line2-home:before {
	content: "\e069";
}
.icon-line2-grid:before {
	content: "\e06a";
}
.icon-line2-graph:before {
	content: "\e06b";
}
.icon-line2-equalizer:before {
	content: "\e06c";
}
.icon-line2-dislike:before {
	content: "\e06d";
}
.icon-line2-cursor:before {
	content: "\e06e";
}
.icon-line2-control-start:before {
	content: "\e06f";
}
.icon-line2-control-rewind:before {
	content: "\e070";
}
.icon-line2-control-play:before {
	content: "\e071";
}
.icon-line2-control-pause:before {
	content: "\e072";
}
.icon-line2-control-forward:before {
	content: "\e073";
}
.icon-line2-control-end:before {
	content: "\e074";
}
.icon-line2-calendar:before {
	content: "\e075";
}
.icon-line2-bulb:before {
	content: "\e076";
}
.icon-line2-bar-chart:before {
	content: "\e077";
}
.icon-line2-arrow-up:before {
	content: "\e078";
}
.icon-line2-arrow-right:before {
	content: "\e079";
}
.icon-line2-arrow-left:before {
	content: "\e07a";
}
.icon-line2-arrow-down:before {
	content: "\e07b";
}
.icon-line2-ban:before {
	content: "\e07c";
}
.icon-line2-bubble:before {
	content: "\e07d";
}
.icon-line2-camcorder:before {
	content: "\e07e";
}
.icon-line2-camera:before {
	content: "\e07f";
}
.icon-line2-check:before {
	content: "\e080";
}
.icon-line2-clock:before {
	content: "\e081";
}
.icon-line2-close:before {
	content: "\e082";
}
.icon-line2-cloud-download:before {
	content: "\e083";
}
.icon-line2-cloud-upload:before {
	content: "\e084";
}
.icon-line2-doc:before {
	content: "\e085";
}
.icon-line2-envelope:before {
	content: "\e086";
}
.icon-line2-eye:before {
	content: "\e087";
}
.icon-line2-flag:before {
	content: "\e088";
}
.icon-line2-folder:before {
	content: "\e089";
}
.icon-line2-heart:before {
	content: "\e08a";
}
.icon-line2-info:before {
	content: "\e08b";
}
.icon-line2-key:before {
	content: "\e08c";
}
.icon-line2-link:before {
	content: "\e08d";
}
.icon-line2-lock:before {
	content: "\e08e";
}
.icon-line2-lock-open:before {
	content: "\e08f";
}
.icon-line2-magnifier:before {
	content: "\e090";
}
.icon-line2-magnifier-add:before {
	content: "\e091";
}
.icon-line2-magnifier-remove:before {
	content: "\e092";
}
.icon-line2-paper-clip:before {
	content: "\e093";
}
.icon-line2-paper-plane:before {
	content: "\e094";
}
.icon-line2-plus:before {
	content: "\e095";
}
.icon-line2-pointer:before {
	content: "\e096";
}
.icon-line2-power:before {
	content: "\e097";
}
.icon-line2-refresh:before {
	content: "\e098";
}
.icon-line2-reload:before {
	content: "\e099";
}
.icon-line2-settings:before {
	content: "\e09a";
}
.icon-line2-star:before {
	content: "\e09b";
}
.icon-line2-symbol-female:before {
	content: "\e09c";
}
.icon-line2-symbol-male:before {
	content: "\e09d";
}
.icon-line2-target:before {
	content: "\e09e";
}
.icon-line2-volume-1:before {
	content: "\e09f";
}
.icon-line2-volume-2:before {
	content: "\e0a0";
}
.icon-line2-volume-off:before {
	content: "\e0a1";
}
.icon-line2-users:before {
	content: "\e001";
}
@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower

@import "common/global";
@import "common/bootstrap";
@import "components/buttons";
@import "components/comments";
@import "components/grid";
@import "components/wp-classes";
/* ======== Canvas Theme ======== */

// Initialize
@import "canvas/variables.scss";
@import "canvas/mixins.scss";


// Core CSS
@import "canvas/typography.scss";
@import "canvas/helpers.scss";
@import "canvas/layouts.scss";
@import "canvas/fonts.scss";


// Content Blocks
@import "canvas/blog.scss";
@import "canvas/content.scss";
@import "canvas/events.scss";
@import "canvas/header.scss";
@import "canvas/pagetitle.scss";
@import "canvas/portfolio.scss";
@import "canvas/shop.scss";
@import "canvas/sliders.scss";
@import "canvas/topbar.scss";

// Shortcodes
@import "canvas/shortcodes/countdown.scss";
@import "canvas/shortcodes/buttons.scss";
@import "canvas/shortcodes/promo.scss";
@import "canvas/shortcodes/feature-box.scss";
@import "canvas/shortcodes/process-steps.scss";
@import "canvas/shortcodes/alerts.scss";
@import "canvas/shortcodes/styled-icons.scss";
@import "canvas/shortcodes/social-icons.scss";
@import "canvas/shortcodes/toggles-accordions.scss";
@import "canvas/shortcodes/tabs.scss";
@import "canvas/shortcodes/faqs.scss";
@import "canvas/shortcodes/clients.scss";
@import "canvas/shortcodes/testimonials.scss";
@import "canvas/shortcodes/team.scss";
@import "canvas/shortcodes/pricing.scss";
@import "canvas/shortcodes/counter-skills.scss";
@import "canvas/shortcodes/carousel.scss";
@import "canvas/shortcodes/overlay.scss";
@import "canvas/shortcodes/headings.scss";
@import "canvas/shortcodes/dividers.scss";
@import "canvas/shortcodes/youtubebg.scss";
@import "canvas/shortcodes/misc.scss";

// Footer
@import "canvas/footer.scss";

// Widgets
@import "canvas/widgets.scss";

// Extras
@import "canvas/extras.scss";
@import "canvas/dark.scss";
@import "canvas/responsive.scss";

/* ===== Custom styling ===== */
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "components/forms";
#footer.dark {
  background-color: $brand-primary;
}

#footer-logo {
  margin-bottom: 20px;
}

#footer .widget {
  margin-top: 0;
}

//-------------------- Base Colors --------------------//

$theme-color:                   #89C8BD;
$color-main:                    #455674;
$body-color:                    #555;
$heading-color:                 #444;



//-------------------- Line Height Variables --------------------//

$line-height-base:              1.5;
$line-height-content:           1.8;


//-------------------- Margins --------------------//

$margin-base:                   50px;
$margin-small:                  30px;
$margin-big:                    80px;



//-------------------- Font Families --------------------//

$body-font:                     'Lato', sans-serif;
$heading-font:                  'Raleway', sans-serif;
$secondary-font:                'Crete Round', serif;


//-------------------- Font Sizes --------------------//

$font-size-base:                14px;
$font-size-h1:                  36px;
$font-size-h2:                  30px;
$font-size-h3:                  24px;
$font-size-h4:                  18px;
$font-size-h5:                  $font-size-base;
$font-size-h6:                  12px;


$success: 						#3C763D;
$info:    						#31708F;
$warning: 						#8A6D3B;
$danger:  						#A94442;

//-------------------- Layouts --------------------//

$postcontent:                   860px;
$postcontent-margin:            40px;

$sidebar:                       1140px - ($postcontent + $postcontent-margin);
$bothsidebar:                   1140px - ( ( $sidebar * 2 ) + ( $postcontent-margin * 2 ) );

$postcontent-md:				690px;
$postcontent-margin-md:			40px;

$sidebar-md:					940px - ($postcontent-md + $postcontent-margin-md);
$bothsidebar-md:				940px - ( ( $sidebar-md * 2 ) + ( $postcontent-margin-md * 2 ) );

$section-padding:				60px;
$section-margin:				60px;
$section-bg:					#F9F9F9;


// Top Bar

$topbar-height:                 45px;
$topbar-line-height:            ($topbar-height - 1);


//-------------------- Header --------------------//

$header-height:                 100px;

// Sticky Header
$sticky-header-height:          60px;

// Floating Header Offset
$header-floating-offset:        60px;

// Side Header
$side-header-width:             260px;
$side-header-bg:                #FFF;



//-------------------- Primary Menu --------------------//

$primary-menu-padding:					( $header-height - 22 ) / 2;
$sticky-menu-padding:					( $sticky-header-height - 22 ) / 2;

$primary-menu-font:             		$heading-font;
$primary-menu-font-weight:      		bold;
$primary-menu-font-size:        		13px;
$primary-menu-font-tt:          		uppercase;
$primary-menu-font-color:       		#444;
$primary-menu-font-spacing:     		1px;


// Primary Menu Sub Menu
$primary-menu-submenu-width:            220px;
$primary-menu-submenu-font:             $body-font;
$primary-menu-submenu-font-size:        12px;
$primary-menu-submenu-font-weight:      600;


// Primary Mega menu
$mega-menu-title:               		$heading-font;



//-------------------- Page Title-------------------- //

$page-title-padding:					50px;
$page-title-parallax-padding:			100px;
$page-title-mini-padding:				20px;

$page-title-bg:							#F5F5F5;
$page-title-bg-dark:					#333;

$page-title-size:						28px;
$page-title-subtitle-size:				18px;

$page-title-parallax-size:				40px;
$page-title-parallax-subtitle-size:		22px;

$page-title-mini-size:					18px;


//-------------------- Portfolio --------------------//

//Columns Margin
$portfolio-2-margin:            		20px;
$portfolio-3-margin:            		15px;
$portfolio-4-margin:            		12px;
$portfolio-5-margin:            		10px;
$portfolio-6-margin:            		6px;

//Columns Margin for Sidebar
$portfolio-2-margin-sb:         		20px;
$portfolio-3-margin-sb:         		15px;
$portfolio-4-margin-sb:         		12px;
$portfolio-5-margin-sb:         		0;

//Columns Margin for Both Sidebar
$portfolio-2-margin-bs:         		20px;
$portfolio-3-margin-bs:         		11px;
$portfolio-4-margin-bs:         		0;



//-------------------- Shop --------------------//

//Columns Margin
$shop-3-margin:							30px;
$shop-4-margin:            				20px;

//Columns Margin for Sidebar
$shop-2-margin-sb:         				40px;
$shop-3-margin-sb:         				25px;

//Columns Margin for Both Sidebar
$shop-2-margin-bs:         				40px;



//-------------------- Blog --------------------//

//Columns Margin
$blog-2-margin:            				40px;
$blog-3-margin:            				30px;
$blog-4-margin:            				28px;


//Columns Margin for Sidebar
$blog-2-margin-sb:         				40px;
$blog-3-margin-sb:         				25px;


//Columns Margin for Both Sidebar
$blog-2-margin-bs:         				40px;



//-------------------- Social Icons Colors --------------------//

$facebook:                 #3B5998;
$delicious:                #205CC0;
$paypal:                   #00588B;
$flattr:                   #F67C1A;
$android:                  #A4C639;
$smashmag:                 #E53B2C;
$gplus:                    #DD4B39;
$wikipedia:                #333;
$stumbleupon:              #F74425;
$foursquare:               #25A0CA;
$call:                     #444;
$ninetyninedesigns:        #F26739;
$forrst:                   #5B9A68;
$digg:                     #191919;
$spotify:                  #81B71A;
$reddit:                   #C6C6C6;
$blogger:                  #FC4F08;
$cc:                       #688527;
$dribbble:                 #EA4C89;
$evernote:                 #5BA525;
$flickr:                   #FF0084;
$google:                   #DD4B39;
$instapaper:               #333;
$klout:                    #FF5F52;
$linkedin:                 #0E76A8;
$vk:                       #2B587A;
$rss:                      #EE802F;
$skype:                    #00AFF0;
$twitter:                  #00ACEE;
$youtube:                  #C4302B;
$vimeo:                    #86C9EF;
$aim:                      #FCD20B;
$yahoo:                    #720E9E;
$email3:                   #6567A5;
$macstore:                 #333333;
$myspace:                  #666666;
$podcast:                  #E4B21B;
$cloudapp:                 #525557;
$dropbox:                  #3D9AE8;
$ebay:                     #89C507;
$github:                   #171515;
$googleplay:               #DD4B39;
$itunes:                   #222;
$plurk:                    #CF5A00;
$pinboard:                 #0000E6;
$soundcloud:               #FF7700;
$tumblr:                   #34526F;
$wordpress:                #1E8CBE;
$yelp:                     #C41200;
$intensedebate:            #009EE4;
$eventbrite:               #F16924;
$scribd:                   #666666;
$stripe:                   #008CDD;
$print:                    #111;
$dwolla:                   #FF5C03;
$statusnet:                #131A30;
$acrobat:                  #D3222A;
$drupal:                   #27537A;
$buffer:                   #333333;
$pocket:                   #EE4056;
$bitbucket:                #0E4984;
$stackoverflow:            #EF8236;
$hackernews:               #FF6600;
$xing:                     #126567;
$instagram:                #3F729B;
$quora:                    #A82400;
$openid:                   #E16309;
$steam:                    #111;
$amazon:                   #E47911;
$disqus:                   #E4E7EE;
$plancast:                 #222;
$appstore:                 #000;
$pinterest:                #C8232C;
$fivehundredpx:            #111;
$si_text_color:            #FFF;